import React from 'react'
import { ProgressSteps, ProgressStepState, TextWidget } from '@revolut/ui-kit'
import { StageProgressStep } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/ProgressWidget/StageProgressStep'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { TimelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import isPast from 'date-fns/isPast'
import { stageToLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/ProgressWidget/constants'
import { formatStagePeriod } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatStagePeriod'

const getState = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
): ProgressStepState => {
  const endDay = TimelineModel.getStageEndDay(cycle, stage)

  if (endDay === null) {
    return 'default'
  }

  if (isPast(endDay)) {
    return 'done'
  }

  if (TimelineModel.isOngoingStage(cycle, stage)) {
    return 'pending'
  }

  return 'default'
}

interface Props {
  readonly cycle: ReviewCyclesInterface
  readonly stages: ReviewCycleStage[]
  readonly selectedStage: ReviewCycleStage
  readonly handleSelectionChange: (stage: ReviewCycleStage) => () => void
}

export const ProgressWidget = ({
  cycle,
  stages,
  selectedStage,
  handleSelectionChange,
}: Props) => {
  return (
    <TextWidget>
      <TextWidget.Title>Timeline</TextWidget.Title>
      <TextWidget.Content>
        <ProgressSteps>
          {stages.map(stage => (
            <StageProgressStep
              key={stage}
              state={getState(cycle, stage)}
              highlight={selectedStage === stage}
              title={stageToLabelMap[stage]}
              description={formatStagePeriod(cycle, stage)}
              onClick={handleSelectionChange(stage)}
            />
          ))}
        </ProgressSteps>
      </TextWidget.Content>
    </TextWidget>
  )
}
