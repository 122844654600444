import { Box, Flex, Icon, Tag, Text, Token, VStack, chain } from '@revolut/ui-kit'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  PerformanceRating,
  Ratings,
  ReviewerRelationToShortTitle,
} from '@src/interfaces/performance'
import React from 'react'
import { tooltipRatingToColor } from '@src/utils/performance'
import { getComparedRatingSettings } from '@src/constants/columns/performanceSummary'
import { SkillLevels } from '@src/interfaces/roles'

const EMPTY_GRADE = '-'

export const RatingWithTooltip = ({
  getTitle,
  rating,
  ratings,
  showAsText,
  expectedLevel,
}: {
  getTitle: (rating: PerformanceRating) => string
  rating?: PerformanceRating
  ratings?: Ratings[]
  showAsText?: boolean
  expectedLevel?: SkillLevels
}) => {
  if (!rating) {
    return <Text variant="h6">-</Text>
  }
  const setting = getComparedRatingSettings(expectedLevel, rating)
  return (
    <Tooltip
      placement="top"
      body={
        <Box width="max-content" maxWidth={400} p="s-8">
          <VStack>
            {ratings?.map(item => (
              <Flex flex="1" alignItems="center" key={item.review.id}>
                {chain(
                  <Text variant="h6" color={tooltipRatingToColor(item.value)}>
                    {getTitle(item.value)}
                  </Text>,
                  <Text color={Token.color.greyTone50}>
                    {item.review.reviewer.full_name}
                  </Text>,
                  <Text color={Token.color.greyTone50}>
                    {ReviewerRelationToShortTitle[item.review.reviewer_relation]}
                  </Text>,
                )}
              </Flex>
            ))}
          </VStack>
        </Box>
      }
    >
      <Flex gap="s-8" width="fit-content" style={{ cursor: 'pointer' }}>
        {showAsText && (
          <Text variant="heading3" data-testid="card-result">
            {getTitle(rating)}
          </Text>
        )}
        {!showAsText && <Tag color={setting.color}>{getTitle(rating)}</Tag>}
        {getTitle(rating) !== EMPTY_GRADE && !showAsText && (
          <Icon size={16} name={setting.icon} color={setting.color} />
        )}
      </Flex>
    </Tooltip>
  )
}
