import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/CommonActions'
import { useCloseReviewCycle } from '@src/api/reviewCycles'
import { MoreBar, Text } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

interface Props {
  readonly cycle: ReviewCyclesInterface
}

export const OngoingCycleActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { mutateAsync: closeCycle } = useCloseReviewCycle(id)
  const showStatusPopup = useShowStatusPopup()

  const handleStopCycle = async () => {
    await closeCycle()
    showStatusPopup({ title: `${cycle.name} cycle is successfully stopped` })
  }

  return (
    <MoreBar maxCount={2} aria-label="action panel">
      <CommonActions cycle={cycle} />
      <NewSaveButtonWithPopup<ReviewCyclesInterface>
        confirmationDialogue={{
          body: <Text>Are you sure you want to stop the cycle?</Text>,
        }}
        useIcon="Stop"
        isMoreAction
        onClick={handleStopCycle}
      >
        Stop Cycle
      </NewSaveButtonWithPopup>
    </MoreBar>
  )
}
