import React from 'react'
import { Caption, Flex, Text, Token } from '@revolut/ui-kit'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'

interface Props {
  readonly title: string
  readonly description: string
}

const OptionLabel = ({ title, description }: Props) => (
  <Flex flexDirection="column">
    <Text>{title}</Text>
    <Caption variant="caption" color={Token.color.greyTone50}>
      {description}
    </Caption>
  </Flex>
)

export const scheduledOption: RadioOption = {
  value: 'scheduled',
  label: (
    <OptionLabel title="Schedule" description="Cycle stages change on fixed dates" />
  ),
}

export const manualOption: RadioOption = {
  value: 'manual',
  disabled: true,
  label: <OptionLabel title="Manual" description="Move to the next stage manually" />,
}

export const editOptions: RadioOption[] = [scheduledOption, manualOption]
