import { IconName } from '@revolut/ui-kit'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface WidgetElements {
  readonly iconName: IconName
  readonly title: string
  readonly description: string
}

const departmentGoalsElements: WidgetElements = {
  iconName: 'Flag',
  title: 'Department Goals setting',
  description: 'Define the targets and outcomes we want to achieve in the quarter',
}

const teamGoalsElements: WidgetElements = {
  iconName: 'Flag',
  title: 'Team Goals setting',
  description: 'Define the targets and outcomes we want to achieve in the quarter',
}

const performanceElements: WidgetElements = {
  iconName: 'DocumentsPair',
  title: 'Performance review',
  description:
    'After you validate and publish the scorecards, employees and managers review their performance of this quarter',
}

const calibrationElements: WidgetElements = {
  iconName: 'BulkSelection',
  title: 'Calibration period',
  description: 'Let senior management calibrate the grades',
}

const managerPublishElements: WidgetElements = {
  iconName: 'Message',
  title: 'Publish to managers',
  description: 'Managers will be able to see the final grades of their reports',
}

const employeePublishElements: WidgetElements = {
  iconName: 'Message',
  title: 'Publish to employees',
  description: 'Employees will be able to see their final grades',
}

export const stageToWidgetElementsMap: Record<ReviewCycleStage, WidgetElements> = {
  [ReviewCycleStage.DepartmentGoals]: departmentGoalsElements,
  [ReviewCycleStage.TeamGoals]: teamGoalsElements,
  [ReviewCycleStage.Review]: performanceElements,
  [ReviewCycleStage.Calibration]: calibrationElements,
  [ReviewCycleStage.ManagersPublish]: managerPublishElements,
  [ReviewCycleStage.EmployeesPublish]: employeePublishElements,
}
