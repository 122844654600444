import { Avatar, Caption, Details, Flex, Icon, Link, Tip } from '@revolut/ui-kit'
import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { stageToWidgetElementsMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { formatStagePeriod } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatStagePeriod'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  readonly cycle: ReviewCyclesInterface
  readonly stage: ReviewCycleStage
}

export const StageWidget = ({ cycle, stage }: Props) => {
  const params = useParams<{ id: string }>()
  const editStagePath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, params)
  const { iconName, title, description } = stageToWidgetElementsMap[stage]
  const stagePeriod = formatStagePeriod(cycle, stage, false)

  return (
    <Tip>
      <Tip.Avatar>
        <Avatar useIcon={iconName} />
      </Tip.Avatar>
      <Tip.Title>{title}</Tip.Title>
      <Tip.Description>{description}</Tip.Description>
      <Tip.Actions>
        <Details>
          <Link to={editStagePath} use={InternalLink}>
            <Flex alignItems="center">
              <Icon name="Calendar" size={16} />
              <Caption px="s-4">{stagePeriod}</Caption>
              <Icon name="Pencil" size={16} />
            </Flex>
          </Link>
        </Details>
      </Tip.Actions>
    </Tip>
  )
}
